import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import * as State from "./state";
import * as SharedState from "../shared-state";
import { Container } from "./elements";
import { WorkModal } from "./components/work-modal";
import { HeaderBar } from "./components/header-bar";
import { WorkArea } from "./components/work-area";
import * as Types from "./types";
import * as Product from "./product";
import * as System from "./system";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import { Alerts } from "./components/alerts";
import { clientConfig } from "../config";
import { AddComponentGroup } from "./add-component-menu/";
import { ErrorSeverity } from "@genesys/primitives";
import {
  Fan,
  Heater,
  Cooler,
  Section,
  SectionDefault,
  Filter,
  OperatingCases,
  SystemFeatures,
  MechanicalOptions,
  ElectricalOptions,
  PlenumSize,
  PropertyGroups,
  MechanicalSketch,
  Pid,
  PsychrometricChart,
  Energy,
  BuildInRevit,
  SolidContact,
  SystemAccessories,
  Icon,
  SpareParts,
  ServiceOptions,
  Condenser,
  BlackBox,
  WiringDiagram
} from "@genesys/ui-elements";
import * as TopMenu from "./top-menu";
import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as CaseTypeEnum from "@genesys/shared/lib/enums/case-type";
import { ExchangeComponentDialog } from "./components/exchange-component-dialog";
import { GenericDialog } from "./components/generic-dialog";
import * as GraphQlTypes from "../graphql-types";
import { OperatingCaseResult } from "@genesys/shared/src/components-messages";
import * as ComponentsMessages from "@genesys/shared/lib/components-messages";

export interface Props {
  readonly dispatch: Dispatch<State.Action>;
  readonly state: State.State;
  readonly sharedState: SharedState.State;
  readonly traceId: string | undefined;
}

export function View(props: Props): JSX.Element {
  const openModal = (initModal: Types.InitModal) =>
    props.dispatch(State.Action.openModal(initModal));
  const closeModal = () => props.dispatch(State.Action.closeModal());
  const saveModal = () => props.dispatch(State.Action.saveModal());
  const saveAndCalculate = () =>
    props.dispatch(State.Action.saveAndCalculate());
  const saveAndCalculateEnergy = () =>
    props.dispatch(State.Action.saveAndCalculateEnergy());

  const sysComponent = props.state.system.components.find(c =>
    c.productId.endsWith("SYS")
  )!;

  const systemImage = getSystemImage(
    props.state.productData.systemType.images,
    sysComponent.properties,
    clientConfig.genesysBackend
  );

  const findScreen = (
    screenType: GraphQlTypes.ScreenType
  ): Product.Screen | undefined =>
    props.state.productData.systemType.screens
      .filter(s =>
        PropertyFilterHelpers.isValid(
          s.propertyFilter,
          PropertyFilter.Empty,
          sysComponent.properties
        )
      )
      .find(s => s.screenType === screenType);

  const adjustPriceScreen = findScreen(GraphQlTypes.ScreenType.ADJUST_PRICE);
  const systemPrintoutScreen = findScreen(
    GraphQlTypes.ScreenType.SYSTEM_PRINTOUT
  );

  const claimFilterString = adjustPriceScreen?.claimFilter
    ? adjustPriceScreen.claimFilter.replace(
        /\{systemsalesorg\}/,
        props.state.system.salesOrganisationId
      )
    : "";

  const showAdjustPriceScreen =
    !!adjustPriceScreen &&
    Authorization.checkClaimFilter(
      props.sharedState.user.applicationClaims,
      PropertyFilterHelpers.createPropertyFilter(
        claimFilterString,
        PropertyFilter.fromString("1=0")!
      )
    );

  const showSystemPrintoutScreen =
    !!systemPrintoutScreen &&
    Authorization.checkClaimFilter(
      props.sharedState.user.applicationClaims,
      PropertyFilterHelpers.createPropertyFilter(
        systemPrintoutScreen?.claimFilter ?? "",
        PropertyFilter.fromString("1=0")!
      )
    );

  const binSelections = PropertyValueSet.fromString(
    props.state.system.binSelections || ""
  );
  const binCasesExists = props.state.system.operatingCases.some(
    oc => oc.caseType === CaseTypeEnum.CaseType.Bin
  );

  const componentsMessages = props.state.system.components.flatMap(c =>
    c.messages.map(m => ({
      id: m.id,
      componentId: c.id,
      productId: c.productId,
      properties: c.properties,
      code: m.messageCode,
      severity: m.messageSeverity,
      parameters:
        (m.messageParameters &&
          PropertyValueSet.fromString(m.messageParameters)) ||
        PropertyValueSet.Empty,
      operatingCaseResultId: m.operatingCaseResultId
    }))
  );

  const operatingCaseResults = props.state.system.operatingCases.reduce<
    ReadonlyArray<OperatingCaseResult>
  >((a, b) => {
    return [
      ...a,
      ...b.results.map(r => ({
        id: r.id,
        componentId: r.componentId,
        calculationType: r.calculationType,
        caseType: b.caseType,
        operatingCase: {
          id: b.id,
          caseName: b.caseName,
          customCaseName: b.customCaseName ?? undefined,
          sortNo: b.sortNo
        }
      }))
    ];
  }, []);

  const productsCaseFilters = props.state.productData.products.map(p => ({
    productId: p.id,
    caseFilters: p.caseFilters
  }));

  const claimFilteredComponentMessages =
    ComponentsMessages.getClaimFilteredComponentMessages(
      componentsMessages,
      operatingCaseResults,
      props.state.productData.systemType.caseFilters,
      productsCaseFilters,
      props.sharedState.user.applicationClaims
    );

  return (
    <>
      <WorkModal
        productData={props.state.productData}
        componentsMessages={claimFilteredComponentMessages}
        calculate={() => props.dispatch(State.Action.calculate())}
        onSaveAndCalculate={saveAndCalculate}
        onSaveAndCalculateEnergy={saveAndCalculateEnergy}
        dispatch={props.dispatch}
        onClose={closeModal}
        modal={props.state.modal}
        title={props.state.modal.type}
        system={props.state.system}
        systemType={props.state.productData.systemType}
        products={props.state.productData.products}
        onSave={saveModal}
        sharedState={props.sharedState}
      />
      <Container>
        <HeaderBar
          cancelLock={() =>
            props.dispatch(State.Action.toggleConfirmLockRevisonDialog())
          }
          showConfirmLockRevision={props.state.showConfirmLockRevision}
          showEnergyBinSelectionsValidationError={
            props.state.showEnergyBinSelectionsValidationError
          }
          lockSystem={() => props.dispatch(State.Action.lock())}
          calculateMCompare={
            !PropertyValueSet.isEmpty(binSelections) &&
            binCasesExists &&
            props.state.system.components.some(c => c.productId.endsWith("MCS"))
          }
          calculateEnergy={props.state.system.operatingCases.some(
            oc => oc.caseType === CaseTypeEnum.CaseType.Bin
          )}
          sharedState={props.sharedState}
          filesClick={() =>
            openModal({
              type: "NotesAndDocuments"
            })
          }
          systemStatus={props.state.system.status}
          priceClick={() => openModal({ type: "Price" })}
          printClick={() => openModal({ type: "Print" })}
          systemInfoProps={{
            showAdjustPrice: showAdjustPriceScreen,
            showSystemPrintoutScreen: showSystemPrintoutScreen,
            accessToken: props.sharedState.accessToken,
            img: systemImage,
            genesysNo: props.sharedState.genesysPrefix.genesysNo(
              props.state.system.file.genesysNo,
              props.state.system.revisionNo
            ),
            system: props.state.system,
            systemType: props.state.productData.systemType,
            sharedState: props.sharedState,
            systemInfoMenuProps: {
              dispatch: props.dispatch,
              actionCompleteUrl: props.state.actionCompleteUrl,
              sharedState: props.sharedState,
              systemActionsState: props.state.systemActionsState,
              system: props.state.system,
              systemType: props.state.productData.systemType,
              toggleSystemActions: () =>
                props.dispatch(State.Action.toggleSystemActions())
            }
          }}
          translate={props.sharedState.translate}
          calculate={() => props.dispatch(State.Action.calculate())}
          oncalculateMCompare={() =>
            props.dispatch(State.Action.tryCalculateEnergyComparison())
          }
          oncalculateEnergy={() =>
            props.dispatch(State.Action.tryCalculateEnergy())
          }
          setActiveAmountField={amountProfileId =>
            props.dispatch(State.Action.setActiveAmountField(amountProfileId))
          }
          toggleEnergyBinSelectionsValidationError={() =>
            props.dispatch(
              State.Action.togglesEnergyBinSelectionsValidationError()
            )
          }
          traceId={props.traceId}
        />
        <Alerts
          onComponentMessageClick={componentId => {
            const isOpcComponent = props.state.system.components
              .find(c => c.id === componentId)
              ?.productId.toUpperCase()
              .endsWith("OPC");

            props.dispatch(
              State.Action.openModal(
                isOpcComponent
                  ? { type: "OperatingCases" }
                  : {
                      type: "ComponentEditor",
                      selectedComponentId: componentId
                    }
              )
            );
          }}
          onComponentMessageHover={componentId =>
            props.dispatch(State.Action.setSelectedComponent(componentId))
          }
          sharedState={props.sharedState}
          componentsMessages={claimFilteredComponentMessages}
          products={props.state.productData.products}
          systemTypeId={props.state.system.file.systemTypeId}
          expanded={props.state.alertSliderExpanded}
          toggle={() => {
            props.dispatch(State.Action.toggleAlertSlider());
          }}
          expandedGroups={props.state.expandedGroups}
          expandGroupException={key =>
            props.dispatch(State.Action.expandGroupException(key))
          }
          collapseGroupException={key =>
            props.dispatch(State.Action.collapseGroupException(key))
          }
          expandGroupError={key =>
            props.dispatch(State.Action.expandGroupError(key))
          }
          collapseGroupError={key =>
            props.dispatch(State.Action.collapseGroupError(key))
          }
          expandGroupWarning={key =>
            props.dispatch(State.Action.expandGroupWarning(key))
          }
          collapseGroupWarning={key =>
            props.dispatch(State.Action.collapseGroupWarning(key))
          }
          expandGroupCriticalWarning={key =>
            props.dispatch(State.Action.expandGroupCriticalWarning(key))
          }
          collapseGroupCriticalWarning={key =>
            props.dispatch(State.Action.collapseGroupCriticalWarning(key))
          }
          operatingCaseResults={props.state.system.operatingCases.reduce<
            ReadonlyArray<OperatingCaseResult>
          >((a, b) => {
            return [
              ...a,
              ...b.results.map(r => ({
                id: r.id,
                componentId: r.componentId,
                calculationType: r.calculationType,
                caseType: b.caseType,
                operatingCase: {
                  id: b.id,
                  caseName: b.caseName,
                  customCaseName: b.customCaseName ?? undefined,
                  sortNo: b.sortNo
                }
              }))
            ];
          }, [])}
        />
        <TopMenu.TopMenu
          system={props.state.system}
          state={props.state.topMenu}
          dispatch={Dispatch.map(State.Action.dispatchTopMenu, props.dispatch)}
          sharedState={props.sharedState}
          addComponentMenuGroups={createAddComponentMenuGroups(
            props.state.productData.products,
            sysComponent.properties,
            props.sharedState.user.applicationClaims,
            props.state.system.file.systemTypeId
          )}
          engineeringOutputsScreens={createEngineeringOutputsScreens(
            props.state.productData.systemType.screens,
            props.state.system.components,
            sysComponent.properties,
            openModal,
            props.sharedState.user.applicationClaims,
            props.sharedState.translate
          )}
          systemInputsScreens={createSystemInputScreens(
            props.state.productData.systemType.screens,
            props.state.system.components,
            sysComponent,
            openModal,
            props.sharedState.user.applicationClaims,
            props.sharedState.translate
          )}
          translate={props.sharedState.translate}
          addComponent={(
            componentId: string,
            productId: string,
            productSectionIds: ReadonlyArray<string>
          ) =>
            props.dispatch(
              State.Action.setAddComponent(
                componentId,
                productId,
                productSectionIds
              )
            )
          }
          productData={props.state.productData}
          systemStatus={props.state.system.status}
        />
        <WorkArea
          diagramProps={{
            claimFilteredComponentsMessages: claimFilteredComponentMessages,
            system: props.state.system,
            productData: props.state.productData,
            symbols: props.state.productData.systemType.symbols,
            sharedState: props.sharedState,
            mode: props.state.diagramMode,
            diagramHoverState: props.state.diagramHoverState,
            selectedAirPosition: props.state.selectedAirPosition,
            setGenericDialogState: state =>
              props.dispatch(State.Action.setGenericDialogState(state)),
            setDiagramHoverState: state =>
              props.dispatch(State.Action.setDiagramHoverState(state)),
            setSelectedAirPosition: airPosition =>
              props.dispatch(State.Action.setSelectedAirPosition(airPosition)),
            selectComponent: id =>
              props.dispatch(State.Action.setSelectedComponent(id)),
            selectComponentMenu: (id, menu) =>
              props.dispatch(State.Action.setSelectedComponentMenu(id, menu)),
            openAccessories: id =>
              props.dispatch(
                State.Action.openModal({
                  type: "Accessories",
                  selectedComponentId: id
                })
              ),
            editComponent: id =>
              props.dispatch(
                State.Action.openModal({
                  type: "ComponentEditor",
                  selectedComponentId: id
                })
              ),
            addToDiagram: (
              componentId,
              productId,
              productSectionIds,
              selectedPositions
            ) =>
              props.dispatch(
                State.Action.addComponentSelectPosition(
                  componentId,
                  productId,
                  productSectionIds,
                  selectedPositions
                )
              ),
            moveComponent: (componentId, sections) =>
              props.dispatch(
                State.Action.setMoveComponent(componentId, sections)
              ),
            moveComponentSection: (componentId, sections, selectedPositions) =>
              props.dispatch(
                State.Action.moveComponentSelectPosition(
                  componentId,
                  sections,
                  selectedPositions
                )
              ),
            deleteComponent: componentId =>
              props.dispatch(State.Action.deleteComponent(componentId)),
            exchangeComponent: exchange =>
              props.dispatch(State.Action.setExchangeComponent(exchange)),
            openNotes: componentId =>
              props.dispatch(
                State.Action.openModal({
                  type: "ComponentEditor",
                  selectedComponentId: componentId,
                  activeTab: "notes"
                })
              )
          }}
          performanceOverviewProps={{
            dispatch: Dispatch.map(
              State.Action.dispatchPeformanceOverview,
              props.dispatch
            ),
            systemItem: props.state.system,
            systemType: props.state.productData,
            sysProperties: sysComponent.properties,
            sharedState: props.sharedState,
            state: props.state.performanceOverview,
            selectedAirPosition: props.state.selectedAirPosition,
            onAirPositionClick: airPosition =>
              props.dispatch(State.Action.setSelectedAirPosition(airPosition))
          }}
        />
      </Container>
      {props.state.exchangeComponentDialog && (
        <ExchangeComponentDialog
          exchangeComponent={props.state.exchangeComponentDialog}
          productData={props.state.productData}
          system={props.state.system}
          onSelect={input =>
            props.dispatch(State.Action.exchangeComponent(input))
          }
          onClose={() =>
            props.dispatch(State.Action.setExchangeComponent(undefined))
          }
          translate={props.sharedState.translate}
        />
      )}
      {props.state.genericDialogState && (
        <GenericDialog
          title={props.state.genericDialogState.title}
          message={props.state.genericDialogState.message}
          onClose={() =>
            props.dispatch(State.Action.setGenericDialogState(undefined))
          }
          onConfirm={props.state.genericDialogState!.onConfirm}
          translate={props.sharedState.translate}
        />
      )}
    </>
  );
}

function createAddComponentMenuGroups(
  allProducts: ReadonlyArray<Product.Product>,
  sysProperties: PropertyValueSet.PropertyValueSet,
  claims: Authorization.ApplicationClaims,
  systemTypeId: string
): ReadonlyArray<AddComponentGroup> {
  const hasBlackBoxPermission = Authorization.hasPermission(
    claims,
    Authorization.systemTypeClaims.canUseBlackBoxComponent,
    systemTypeId
  );

  const groups = allProducts
    .filter(
      p =>
        p.productType === Product.PropertyTypeEnum.FUNCTION &&
        PropertyFilter.isValid(sysProperties, p.rangeFilter) &&
        (!p.id.endsWith("BBX") || hasBlackBoxPermission)
    )
    .reduce((a, b) => {
      if (!b.menuGroup) {
        return a;
      }

      const createProduct = () => ({
        productId: b.id,
        productSectionIds: b.sections
          .filter(s => s.movable)
          .sort((a, b) => (a.sortNo < b.sortNo ? -1 : 1))
          .map(s => s.id)
      });

      if (a[b.menuGroup]) {
        return {
          ...a,
          [b.menuGroup]: {
            ...a[b.menuGroup],
            products: a[b.menuGroup].products.concat(createProduct())
          }
        };
      }

      return {
        ...a,
        [b.menuGroup]: {
          group: b.menuGroup,
          Icon: getAddComponentGroupIcon(b.menuGroup),
          products: [createProduct()]
        }
      };
    }, {} as { readonly [key: string]: AddComponentGroup });

  return Object.keys(groups).map(key => groups[key]);
}

function getAddComponentGroupIcon(group: string) {
  switch (group) {
    case "Fan":
      return Fan;
    case "Heater":
      return Heater;
    case "Cooler":
      return Cooler;
    case "Filter":
      return Filter;
    case "Condenser":
      return Condenser;
    case "Section":
      return Section;
    case "Black box":
      return BlackBox;
    default:
      return SectionDefault;
  }
}

function filterScreens(
  screen: Product.Screen,
  screenTypes: ReadonlyArray<Product.ScreenType>,
  sysProperties: PropertyValueSet.PropertyValueSet,
  claims: Authorization.ApplicationClaims
): boolean {
  return (
    screenTypes.includes(screen.screenType) &&
    PropertyFilter.isValid(sysProperties, screen.propertyFilter) &&
    Authorization.checkClaimFilter(
      claims,
      PropertyFilter.fromString(screen.claimFilter || "") ||
        PropertyFilter.Empty
    )
  );
}

function hasComponentErrors(component: System.Component): boolean {
  return (
    component.messages.filter(m => m.messageSeverity >= ErrorSeverity.Error)
      .length > 0
  );
}

function createSystemInputScreens(
  screens: ReadonlyArray<Product.Screen>,
  components: ReadonlyArray<System.Component>,
  sysComponent: System.Component,
  setModal: (modal: Types.InitModal) => void,
  claims: Authorization.ApplicationClaims,
  translate: LanguageTexts.Translate
): ReadonlyArray<TopMenu.Screen> {
  const nativeScreens: ReadonlyArray<TopMenu.Screen> = screens
    .filter(s =>
      filterScreens(
        s,
        [
          Product.ScreenTypeEnum.SYSTEM_SIZE,
          Product.ScreenTypeEnum.PROPERTY_GROUP,
          Product.ScreenTypeEnum.SYSTEM_ACCESSORIES,
          Product.ScreenTypeEnum.MULTIPLE_SYSTEMS_EDITOR,
          Product.ScreenTypeEnum.DATA_CENTER_EDITOR
        ],
        sysComponent.properties,
        claims
      )
    )
    .sort((a, b) => (a.sortNo < b.sortNo ? -1 : 1))
    .map(s => {
      const screen = createScreen(s.screenType, components);
      const title = translate(
        LanguageTexts.dynamicText(s.screenType.replace(/_/g, ""))
      );
      return {
        title: title,
        hasError: screen.hasError ?? false,
        Icon: screen.Icon,
        onClick: () => setModal(screen.modal)
      };
    });

  const systemFeature: TopMenu.Screen | undefined = {
    title: translate(LanguageTexts.product(sysComponent.productId)),
    hasError: hasComponentErrors(sysComponent),
    Icon: SystemFeatures,
    onClick: () =>
      setModal({
        type: "ComponentEditor",
        selectedComponentId: sysComponent.id
      })
  };

  const operatingCasesComponent = components.find(c =>
    c.productId.endsWith("OPC")
  );
  const operatingCasesScreen: TopMenu.Screen | undefined =
    operatingCasesComponent
      ? {
          title: translate(
            LanguageTexts.product(operatingCasesComponent.productId)
          ),
          hasError: hasComponentErrors(operatingCasesComponent),
          Icon: OperatingCases,
          onClick: () =>
            setModal({
              type: "OperatingCases"
            })
        }
      : undefined;

  const mechanicalComponent = components.find(c => c.productId.endsWith("UCT"));
  const mechanicalScreen: TopMenu.Screen | undefined = mechanicalComponent
    ? {
        title: translate(LanguageTexts.product(mechanicalComponent.productId)),
        hasError: hasComponentErrors(mechanicalComponent),
        Icon: MechanicalOptions,
        onClick: () =>
          setModal({
            type: "ComponentEditor",
            selectedComponentId: mechanicalComponent.id
          })
      }
    : undefined;

  const electricalComponent = components.find(c => c.productId.endsWith("CON"));
  const electricalScreen: TopMenu.Screen | undefined = electricalComponent
    ? {
        title: translate(LanguageTexts.product(electricalComponent.productId)),
        hasError: hasComponentErrors(electricalComponent),
        Icon: ElectricalOptions,
        onClick: () =>
          setModal({
            type: "ComponentEditor",
            selectedComponentId: electricalComponent.id
          })
      }
    : undefined;

  const sparePartsComponent = components.find(c => c.productId.endsWith("SPA"));
  const sparePartsScreen: TopMenu.Screen | undefined = sparePartsComponent
    ? {
        title: translate(LanguageTexts.product(sparePartsComponent.productId)),
        hasError: hasComponentErrors(sparePartsComponent),
        Icon: SpareParts,
        onClick: () =>
          setModal({
            type: "ComponentEditor",
            selectedComponentId: sparePartsComponent.id
          })
      }
    : undefined;

  const serviceOptionComponent = components.find(c =>
    c.productId.endsWith("SRV")
  );
  const serviceOptionsScreen: TopMenu.Screen | undefined =
    serviceOptionComponent
      ? {
          title: translate(
            LanguageTexts.product(serviceOptionComponent.productId)
          ),
          hasError: hasComponentErrors(serviceOptionComponent),
          Icon: ServiceOptions,
          onClick: () =>
            setModal({
              type: "ComponentEditor",
              selectedComponentId: serviceOptionComponent.id
            })
        }
      : undefined;

  return [systemFeature]
    .concat(operatingCasesScreen ?? [])
    .concat(electricalScreen ?? [])
    .concat(mechanicalScreen ?? [])
    .concat(nativeScreens)
    .concat(sparePartsScreen ?? [])
    .concat(serviceOptionsScreen ?? []);
}

function createEngineeringOutputsScreens(
  screens: ReadonlyArray<Product.Screen>,
  components: ReadonlyArray<System.Component>,
  sysProperties: PropertyValueSet.PropertyValueSet,
  setModal: (modal: Types.InitModal, title: string) => void,
  claims: Authorization.ApplicationClaims,
  translate: LanguageTexts.Translate
): ReadonlyArray<TopMenu.Screen> {
  return screens
    .filter(s =>
      filterScreens(
        s,
        [
          Product.ScreenTypeEnum.MECHANICAL_SKETCH,
          Product.ScreenTypeEnum.M_COMPARE,
          Product.ScreenTypeEnum.PID_DIAGRAM,
          Product.ScreenTypeEnum.PSYCHROMETRICS_CHART,
          Product.ScreenTypeEnum.ENERGY,
          Product.ScreenTypeEnum.SOLID_CONTACT_BUILDER,
          Product.ScreenTypeEnum.REVIT_BUILDER,
          Product.ScreenTypeEnum.CONDENSATION_ANALYSIS,
          Product.ScreenTypeEnum.WIRING_DIAGRAM,
          Product.ScreenTypeEnum.SERVICE_CALCULATION
        ],
        sysProperties,
        claims
      )
    )
    .sort((a, b) => (a.sortNo < b.sortNo ? -1 : 1))
    .map(s => {
      const customScreenName = PropertyValueSet.getText(
        "customname",
        PropertyValueSet.fromString(s.screenParameters ?? "")
      );

      const titleSource = customScreenName ? customScreenName : s.screenType;

      const title = translate(
        LanguageTexts.dynamicText(titleSource.replace(/_/g, ""))
      );

      const screen = createScreen(s.screenType, components);

      return {
        title: title,
        hasError: screen.hasError ?? false,
        Icon: screen.Icon,
        onClick: () => setModal(screen.modal, title)
      };
    });
}

function createScreen(
  screen: Product.ScreenType,
  components: ReadonlyArray<System.Component>
): {
  readonly Icon: Icon;
  readonly modal: {
    readonly type: Types.ModalTypesExceptComponentEditorAndAccessories;
  };
  readonly hasError?: boolean;
} {
  const getHasError = (productId: string): boolean => {
    const component = components.find(
      c =>
        c.productId.endsWith(productId) &&
        c.messages.filter(m => m.messageSeverity >= ErrorSeverity.Error)
          .length > 0
    );
    return component ? true : false;
  };
  switch (screen) {
    case Product.ScreenTypeEnum.SYSTEM_SIZE:
      return {
        Icon: PlenumSize,
        modal: { type: "PlenumSize" }
      };
    case Product.ScreenTypeEnum.PROPERTY_GROUP:
      return {
        Icon: PropertyGroups,
        modal: { type: "PropertyGroup" }
      };
    case Product.ScreenTypeEnum.MECHANICAL_SKETCH:
      return {
        Icon: MechanicalSketch,
        modal: { type: "MechanicalSketch" }
      };
    case Product.ScreenTypeEnum.PID_DIAGRAM:
      return {
        Icon: Pid,
        modal: { type: "Pid" }
      };
    case Product.ScreenTypeEnum.PSYCHROMETRICS_CHART:
      return {
        Icon: PsychrometricChart,
        modal: { type: "PsychrometricChart" }
      };
    case Product.ScreenTypeEnum.ENERGY:
      return {
        Icon: Energy,
        modal: { type: "Energy" },
        hasError: getHasError("OPE")
      };
    case Product.ScreenTypeEnum.M_COMPARE:
      return {
        Icon: Energy,
        modal: { type: "MCompare" }
      };
    case Product.ScreenTypeEnum.SOLID_CONTACT_BUILDER:
      return {
        Icon: SolidContact,
        modal: { type: "BuildInSc" }
      };
    case Product.ScreenTypeEnum.WIRING_DIAGRAM:
      return {
        Icon: WiringDiagram,
        modal: { type: "WiringDiagram" }
      };
    case Product.ScreenTypeEnum.REVIT_BUILDER:
      return {
        Icon: BuildInRevit,
        modal: { type: "BuildInRevit" }
      };
    case Product.ScreenTypeEnum.SYSTEM_ACCESSORIES:
      return {
        Icon: SystemAccessories,
        modal: { type: "SystemAccessories" }
      };
    case Product.ScreenTypeEnum.MULTIPLE_SYSTEMS_EDITOR:
      return {
        Icon: PlenumSize,
        modal: { type: "MultipleSystemsEditor" }
      };
    case Product.ScreenTypeEnum.DATA_CENTER_EDITOR:
      return {
        Icon: PlenumSize,
        modal: { type: "DataCenterEditor" }
      };

    case Product.ScreenTypeEnum.CONDENSATION_ANALYSIS:
      return {
        Icon: PlenumSize,
        modal: { type: "CondensationAnalysis" }
      };
    case Product.ScreenTypeEnum.SERVICE_CALCULATION:
      return {
        Icon: MechanicalOptions,
        modal: { type: "LockedSystemCalculation" }
      };
    default:
      throw new Error("Unknown screen: " + screen);
  }
}

function getSystemImage(
  images: ReadonlyArray<Product.Image>,
  properties: PropertyValueSet.PropertyValueSet,
  host: string
): string | undefined {
  const url = images.find(
    img =>
      img.imageUsage === Product.ImageUsageEnum.NEW_SYSTEM_WIZARD &&
      PropertyFilter.isValid(properties, img.propertyFilter)
  )?.url;

  return url ? `${host}${url}` : undefined;
}

//tslint:disable-next-line
