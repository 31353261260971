import * as React from "react";
import * as SharedState from "../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import {
  Trash,
  P2,
  Edit,
  ToolTipWrapper,
  zIndex,
  Input,
  Pop,
  S2,
  AddComponent,
  InfoWhite,
  StandardButton,
  PrimaryColors
} from "@genesys/ui-elements";
import { State, Action } from "../state";
import { Dispatch } from "@typescript-tea/core";

const Container = styled.div``;

const SearchContainer = styled.div`
  z-index: ${zIndex.Dropdown};
  border: 1px solid ${PrimaryColors.muntersBlue};
  border-radius: 5px;
  padding: 16px;
  min-width: 350px;
  min-height: 50px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 16px;
  }
`;

const SearchInput = styled(Input)`
  height: 32px;
  width: 250px;
  padding-left: 6px;

  ::-webkit-input-placeholder {
    font-size: 14px;
  }
`;

const MoistureLoadSearchTable = styled.table`
  padding-top: 10px;

  td,
  th {
    padding: 0 10px;
    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  th {
    height: 30px;
    text-align: left;
    h5 {
      display: inline;
      img {
        margin-left: 8px;
      }
    }
  }

  td {
    height: 30px;
    a {
      cursor: pointer;
    }
  }
`;

const ErrorContainer = styled.div<{
  readonly warningType: Type;
}>`
  background-color: ${props =>
    props.warningType === "warning" ? "#f0c530" : "#E8767B"};
  padding-left: 5px;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-right: 10px;
  /* max-height: 80px; */
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;

  > p {
    color: white;
    margin-left: 10px;
    font-size: 13px;
  }
`;

export function MoistureLoadPresenter({
  sharedState,
  state,
  dispatch,
  onMoistureLoadChange
}: {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
  readonly onMoistureLoadChange: (newId: string | undefined) => void;
}) {
  const moistureLoaditem = state.moistureLoadQuery?.user.moistureLoad;

  const edit = (heading: JSX.Element) => {
    return (
      <>
        {heading}
        {anchorEl && (
          <Pop
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            anchor={anchorEl}
            onClose={handleClose}
          >
            <SearchView
              handleClose={handleClose}
              onMoistureLoadChange={onMoistureLoadChange}
              state={state}
              dispatch={dispatch}
              sharedState={sharedState}
            />
          </Pop>
        )}
      </>
    );
  };

  const anchorRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = () => {
    setAnchorEl(anchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(Action.clearSearchResults());
  };

  const translate = sharedState.translate;

  if (!moistureLoaditem) {
    return (
      <Container>
        <FlexContainer>
          <p> {translate(LanguageTexts.connectSystemTomlc())}</p>
          <div>
            {edit(
              <ToolTipWrapper
                onClick={() => {
                  handleClick();
                }}
                title={"Add moisture Load"}
              >
                <span ref={anchorRef}>
                  <AddComponent height="1.3rem" />
                </span>
              </ToolTipWrapper>
            )}
          </div>
        </FlexContainer>
        {state.errorMessage && (
          <WarningBox
            messageContent={state.errorMessage}
            title=""
            warningType="error"
          />
        )}
      </Container>
    );
  }
  return (
    <Container>
      <FlexContainer>
        <p> {translate(LanguageTexts.opcMlcImportText())} </p>

        <P2 weight="bold">
          {": "}
          {sharedState.genesysPrefix.moistureLoadNo(
            moistureLoaditem.moistureloadFile.moistureLoadNo,
            moistureLoaditem.revisionNo
          )}
        </P2>

        <div>
          {edit(
            <ToolTipWrapper
              onClick={() => {
                handleClick();
              }}
              title={"change moisture Load"}
            >
              <span ref={anchorRef}>
                <Edit height={"15px"} />
              </span>
            </ToolTipWrapper>
          )}
        </div>

        <ToolTipWrapper
          onClick={() => {
            onMoistureLoadChange(undefined);
            dispatch(Action.fetchMoistureLoad(undefined));
          }}
          title={"Remove connection"}
        >
          <Trash height={"20px"} />
        </ToolTipWrapper>
      </FlexContainer>
      {state.errorMessage && (
        <WarningBox
          messageContent={state.errorMessage}
          title=""
          warningType="error"
        />
      )}
    </Container>
  );
}

function SearchView({
  sharedState,
  state,
  dispatch,
  onMoistureLoadChange,
  handleClose
}: {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
  readonly handleClose: () => void;
  readonly onMoistureLoadChange: (newId: string | undefined) => void;
}) {
  const moistureLoadSearchResult =
    state.moistureLoadSystemSearch?.user.moistureLoadSystemSearch;
  return (
    <SearchContainer>
      <SearchInputContainer>
        <SearchInput
          value={state.moistureLoadSearchInput}
          onKeyDown={e => {
            if (e.keyCode === 13 && state.moistureLoadSearchInput) {
              dispatch(Action.searchMoistureLoads());
            }
          }}
          onChange={e => {
            dispatch(Action.onMoistureLoadSearchInputChange(e.target.value));
          }}
          placeholder="Moisture Load No"
        />

        <StandardButton
          buttonType="Primary"
          size="Small"
          onClick={() => dispatch(Action.searchMoistureLoads())}
        >
          {sharedState.translate(LanguageTexts.search())}
        </StandardButton>
      </SearchInputContainer>

      {moistureLoadSearchResult && (
        <MoistureLoadSearchTable>
          <thead>
            <tr>
              <th>
                <S2 color="dark" weight="bold">
                  {sharedState.translate(LanguageTexts.moistureLoadNo())}
                </S2>
              </th>
              <th>
                <S2 color="dark" weight="bold">
                  {sharedState.translate(LanguageTexts.name())}
                </S2>
              </th>

              <th />
            </tr>
          </thead>
          <tbody>
            {moistureLoadSearchResult.map(x => (
              <>
                <tr key={x.id}>
                  <td>
                    <P2 weight="normal" color="secondary">
                      {sharedState.genesysPrefix.moistureLoadNo(
                        x.moistureLoadNo,
                        x.revisionNo
                      )}
                    </P2>
                  </td>
                  <td>
                    <P2 weight="normal" color="secondary">
                      {x.name}
                    </P2>
                  </td>
                  <td>
                    <a
                      onClick={() => {
                        /**Todo */
                        onMoistureLoadChange(x.id);
                        dispatch(Action.fetchMoistureLoad(x.id));
                        handleClose();
                      }}
                    >
                      <AddComponent height="1.6rem" />
                    </a>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </MoistureLoadSearchTable>
      )}
    </SearchContainer>
  );
}

type Type = "warning" | "error";

function WarningBox(
  props: {
    readonly warningType: Type;
    readonly messageContent: string;
  } & React.InputHTMLAttributes<HTMLInputElement>
) {
  const { messageContent, warningType } = props;
  return (
    <ErrorContainer warningType={warningType}>
      <InfoWhite />
      <p>{messageContent}</p>
    </ErrorContainer>
  );
}
