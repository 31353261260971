import gql from "graphql-tag";

// AbstractImage
export const userQuery = gql`
  query PidDiagramUserQuery($systemId: ID!) {
    user {
      system(id: $systemId) {
        id
        status
        systemFile {
          id
          name
        }
        airstreams {
          id
          componentSections {
            id
            productSectionId
            componentId
            productSectionSortNo
            sortNo
          }
        }
        components {
          id
          productId
          properties
          sections {
            id
            productSectionId
            componentId
            productSectionSortNo
            sortNo
          }
          messages {
            id
            messageCode
            messageSeverity
          }
        }
      }
    }
  }
`;

export const productQuery = gql`
  query PidDiagramProductQuery($systemTypeInput: GetSystemTypeInputType!) {
    product {
      systemType(input: $systemTypeInput) {
        id
        symbols {
          id
          symbolId
          svg
          width
          height
        }
        allProducts {
          id
          rangeFilter
          properties {
            id
            name
            quantity
          }
          boxConnectionPoints {
            id
            productSectionId
            box
            connectionType
            positionX
            positionY
            positionZ
            mirrorX
            mirrorY
            rotation
            hideStatePoint
            diagramType
            propertyFilter
          }
          boxSymbols {
            id
            box
            symbolId
            positionX
            positionY
            positionZ
            mirrorX
            mirrorY
            rotation
            sizeX
            sizeY
            propertyFilter
            absoluteRotationAndScale
            useForSelectionBounds
            diagramType
          }
          boxTexts {
            id
            box
            source
            text
            fontSize
            positionX
            positionY
            positionZ
            rotation
            absolutePosition
            useAsLabel
            propertyFilter
            diagramType
          }
          boxLinePoints {
            id
            box
            connectionId
            lineType
            positionX
            positionY
            direction
            propertyFilter
            diagramType
          }
        }
      }
    }
  }
`;

// eContact
export const buildMutation = gql`
  mutation BuildPidDiagramMutation($input: PidDiagramBuildInputType!) {
    buildPidDiagram(input: $input) {
      responseCode
    }
  }
`;

export const getBuildStateQuery = gql`
  query getPidDiagramBuildStateUserQuery($input: PidDiagramInputType!) {
    user {
      getPidDiagramBuildState(input: $input) {
        responseCode
      }
    }
  }
`;

export const downloadQuery = gql`
  query downloadPidDiagramUserQuery($input: PidDiagramInputType!) {
    user {
      downloadPidDiagram(input: $input) {
        responseCode
        base64Pdf
      }
    }
  }
`;
