import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as System from "../system";
import * as Product from "../product";
import * as DhuSystemTypeNotes from "./dhu-system-type-notes";
import * as EpxSystemTypeNotes from "./epx-system-type-notes";
import * as HcxSystemTypeNotes from "./hcx-system-type-notes";
import * as HumSystemTypeNotes from "./hum-system-type-notes";
import * as McdSystemTypeNotes from "./mcd-system-type-notes";

export function notes(
  system: System.System,
  systemType: Product.SystemType,
  translate: LanguageTexts.Translate
): ReadonlyArray<string> {
  switch (system.file.systemTypeId) {
    case "DSP":
    case "DSS":
    case "ERD":
    case "HCD":
    case "ICA":
    case "MAA":
    case "MCA":
    case "MCX":
    case "MLP":
    case "MXN":
    case "MXO":
    case "NA2":
    case "PST":
      return DhuSystemTypeNotes.notes(system, systemType, translate);
    case "HCX":
      return HcxSystemTypeNotes.notes(system, translate);
    case "DCA":
      return EpxSystemTypeNotes.notes(system, systemType, translate);
    case "FAA":
    case "FAU":
    case "HUM":
      return HumSystemTypeNotes.notes(system, systemType, translate);
    case "MCD":
      return McdSystemTypeNotes.notes(system, systemType, translate);
    default:
      return [];
  }
}
