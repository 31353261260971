import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../../shared-state";
import * as ComponentEditor from "../../../modals/component-editor";
import * as OperatingCases from "../../../../operating-case-selector";
import * as Accessories from "../../../modals/accessories";
import * as Price from "../../../modals/price";
import * as PsychrometricChart from "../../../modals/psychrometric-chart-presenter";
import * as Energy from "../../../modals/energy";
import * as MCompare from "../../../modals/m-compare";
import * as Pid from "../../../modals/pid";
import * as PropertyGroups from "../../../modals/property-groups";
import * as SystemAccessories from "../../../modals/system-accessories";
import * as PlenumSize from "../../../modals/plenum-size";
import * as NotesAndDocuments from "../../../modals/notes-and-documents";
import * as MultipleSystemsEditor from "../../../modals/multiple-systems-editor";
import * as MechanicalSketch from "../../../modals/mechanical-sketch";
import * as BuildInSc from "../../../modals/build-in-solid-contacts";
import * as BuildInRevit from "../../../modals/build-in-revit";
import * as DataCenterEditor from "../../../modals/data-center-editor";
import * as Product from "../../../product";
import * as System from "../../../system";
import * as CondensationAnalysis from "../../../modals/condensation-analysis";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Printouts from "../../../modals/printouts";
import * as LockedSystemCalculation from "../../../modals/locked-system-calculation";
import * as WiringDiagram from "../../../modals/wiring-diagram";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as Navigation from "../../../../navigation-effect-manager";
import { clientConfig } from "../../../../config";
import { DispatchActions } from "../../action";
import { Modal, InitModal } from "../../types";
import { disableScrolling } from "../shared";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import { ScreenType } from "../../../../graphql-types";

export function initModal(
  initModal: InitModal,
  sharedState: SharedState.State,
  productData: Product.ProductData,
  system: System.System,
  updateUrl: boolean = true
): [Modal, Cmd<DispatchActions>?] {
  disableScrolling();
  const wikiJsUrl = clientConfig.wikiJsBaseUrl.replace("/graphql", "");
  const createSystemBaseUrl = () => {
    return `/system/${sharedState.genesysPrefix.genesysNo(
      system.file.genesysNo,
      system.revisionNo
    )}`;
  };

  const convertModalNameToKebabCase = () => {
    return initModal.type
      .replace(/([a-z])([A-Z])/g, "$1-$2") // Add a hyphen between lowercase and uppercase letters
      .toLowerCase(); // Convert the entire string to lowercase
  };

  const createNavigationUrlCmd = () => {
    return Navigation.pushUrlWithoutReload(
      `${createSystemBaseUrl()}/${convertModalNameToKebabCase()}`
    );
  };

  switch (initModal.type) {
    case "ComponentEditor": {
      const component = system.components.find(
        c => c.id === initModal.selectedComponentId
      );
      if (!component) {
        console.error("Couldn't find component");
        return [{ type: "NoModal" }];
      }

      const product = productData.products.find(product =>
        product.id.endsWith(component.productId)
      );

      if (!product) {
        console.error("Couldn't find product");
        return [{ type: "NoModal" }];
      }
      const [componentEditorState, componentEditorCmd] = ComponentEditor.init(
        sharedState,
        system.file.systemTypeId,
        component,
        system.id,
        system.status,
        initModal.activeTab || "results-summary",
        productData.systemType.caseFilters
      );

      const systemType = component.productId.substring(0, 3);

      const canShowProductCodes = Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysUserClaims.showProductCodes
      );

      const showProductCodesText = canShowProductCodes
        ? ` (${component.productId})`
        : "";

      const textDef = PropertyValueSet.hasProperty(
        "label",
        component.properties
      )
        ? LanguageTexts.curlyTranslate(
            PropertyValueSet.getText("label", component.properties)! +
              showProductCodesText,
            systemType
          )
        : LanguageTexts.curlyTranslate(
            `{${component.productId}}` + showProductCodesText,
            systemType
          );

      return [
        {
          type: "ComponentEditor",
          selectedComponentId: component.id,
          title: sharedState.translate(textDef),
          wikiJsUrl: product.wikiJsPath
            ? wikiJsUrl + "/" + product.wikiJsPath!
            : undefined,
          state: componentEditorState
        },
        Cmd.batch([
          Cmd.map(DispatchActions.dispatchComponentEditor, componentEditorCmd),
          updateUrl
            ? Navigation.pushUrlWithoutReload(
                `${createSystemBaseUrl()}/component/${component.id}`
              )
            : undefined
        ])
      ];
    }
    case "Print": {
      const [state, cmd] = Printouts.init(
        sharedState,
        productData.systemType.id
      );
      return [
        {
          type: "Print",
          title: sharedState.translate(LanguageTexts.systemPrintout()),
          state
        },
        Cmd.batch([
          Cmd.map(DispatchActions.dispatchPrintouts, cmd),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }
    case "OperatingCases": {
      const operatingCaseComponents = system.components.filter(component =>
        component.productId.endsWith("OPC")
      );

      const operatingCases = operatingCaseComponents.map(component => {
        return {
          id: component.id,
          settings: component.properties
            ? component.properties
            : PropertyValueSet.Empty
        };
      });

      const product = productData.products.find(product =>
        product.id.endsWith("OPC")
      )!;

      const [operatingCasesState, operatingCasesCmd] = OperatingCases.init(
        system.climateSettings,
        operatingCases,
        sharedState,
        product,
        system.moistureLoadInfo?.id
      );

      return [
        {
          type: initModal.type,
          state: operatingCasesState,
          title: sharedState.translate(LanguageTexts.operatingCases()),
          wikiJsUrl: product.wikiJsPath
            ? wikiJsUrl + "/" + product.wikiJsPath
            : undefined
        },
        Cmd.batch([
          Cmd.map(DispatchActions.dispatchOperatingCases, operatingCasesCmd),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }
    case "MechanicalSketch": {
      const [mechanicalSketchState, mechanicalSketchCmd] =
        MechanicalSketch.init(
          productData.systemType.images,
          productData.systemType.id,
          sharedState
        );
      return [
        {
          type: initModal.type,
          state: mechanicalSketchState,
          title: sharedState.translate(LanguageTexts.mechanicalSketch())
        },
        Cmd.batch([
          Cmd.map(
            DispatchActions.dispatchMechanicalSketch,
            mechanicalSketchCmd
          ),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }
    case "NotesAndDocuments": {
      const [notesAndDocumentsState] = NotesAndDocuments.init(
        system.id,
        system.status,
        system.components
      );
      return [
        {
          type: initModal.type,
          state: notesAndDocumentsState,
          title: sharedState.translate(LanguageTexts.notes())
        },
        Cmd.batch([updateUrl ? createNavigationUrlCmd() : undefined])
      ];
    }
    case "Price": {
      const [priceState, priceCmd] = Price.init(
        sharedState,
        productData.systemType.id
      );
      return [
        {
          type: initModal.type,
          state: priceState,
          title: sharedState.translate(LanguageTexts.adjustPrice())
        },
        Cmd.batch([
          Cmd.map(DispatchActions.dispatchPrice, priceCmd),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }
    case "Accessories": {
      const component = system.components.find(
        c => c.id === initModal.selectedComponentId
      );
      if (!component) {
        console.error("Couldn't find component");
        return [{ type: "NoModal" }];
      }

      const product = productData.products.find(product =>
        product.id.endsWith(component.productId)
      )!;

      if (!product) {
        console.error("Couldn't find  product");
        return [{ type: "NoModal" }];
      }
      const [accessoriesState] = Accessories.init(
        component,
        system,
        productData.products
      );
      return [
        {
          type: initModal.type,
          selectedComponentId: component.id,
          state: accessoriesState,
          title: sharedState.translate(LanguageTexts.accessories())
        },
        updateUrl
          ? Navigation.pushUrlWithoutReload(
              `${createSystemBaseUrl()}/accessories-for-component/${
                component.id
              }`
            )
          : undefined
      ];
    }
    case "BuildInSc": {
      const [buildInScState, buildInScCmd] = BuildInSc.init(
        sharedState,
        system.id
      );
      return [
        {
          type: initModal.type,
          state: buildInScState,
          title: sharedState.translate(LanguageTexts.solidContactBuilder())
        },
        Cmd.batch([
          Cmd.map(DispatchActions.dispatchBuildInSc, buildInScCmd),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }
    case "BuildInRevit": {
      const [buildInRevitState, buildInRevitCmd] = BuildInRevit.init(
        sharedState,
        system.id,
        system.operatingCases[0].id
      );
      return [
        {
          type: initModal.type,
          state: buildInRevitState,
          title: sharedState.translate(LanguageTexts.revitBuilder())
        },
        Cmd.batch([
          Cmd.map(DispatchActions.dispatchBuildInRevit, buildInRevitCmd),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }
    case "PsychrometricChart": {
      const [PsychrometricChartState, PsychrometricChartCmd] =
        PsychrometricChart.init(
          system.operatingCases[0].id,
          system.id,
          sharedState
        );

      return [
        {
          type: initModal.type,
          state: PsychrometricChartState,
          title: sharedState.translate(LanguageTexts.psychrometricChart())
        },
        Cmd.batch([
          Cmd.map(
            DispatchActions.dispatchPsychrometricChart,
            PsychrometricChartCmd
          ),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }
    case "Pid": {
      const screenParameters = productData.systemType.screens.find(
        s => s.screenType === ScreenType.PID_DIAGRAM
      )?.screenParameters;

      const useEContact =
        screenParameters !== undefined
          ? PropertyFilter.isValid(
              PropertyValueSet.fromString(screenParameters),
              PropertyFilter.fromString("useecontact=1")!
            )
          : false;

      const sysProperties = system.components.find(component =>
        component.productId.endsWith("SYS")
      )!.properties;

      const diagramType: Pid.DiagramType = useEContact
        ? "e-contact"
        : "abstract-image";

      const title = useEContact
        ? sharedState.translate(LanguageTexts.pidDiagramEContact())
        : sharedState.translate(LanguageTexts.pidDiagram());

      const [pidState, pidCmd] = Pid.init(
        diagramType,
        sharedState,
        system.id,
        system.file.systemTypeId,
        sysProperties
      );

      return [
        {
          type: initModal.type,
          state: pidState,
          title: title,
          useEContact: useEContact
        },
        Cmd.batch([
          Cmd.map(DispatchActions.dispatchPid, pidCmd),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }
    case "PropertyGroup": {
      const [propertyGroupsState] = PropertyGroups.init(
        system,
        productData.systemType,
        productData.products
      );

      return [
        {
          type: initModal.type,
          state: propertyGroupsState,
          title: sharedState.translate(LanguageTexts.propertyGroup())
        },
        updateUrl ? createNavigationUrlCmd() : undefined
      ];
    }
    case "SystemAccessories": {
      const [systemAccessoriesState] = SystemAccessories.init(
        system,
        productData.products
      );

      return [
        {
          type: initModal.type,
          state: systemAccessoriesState,
          title: sharedState.translate(LanguageTexts.systemAccessories())
        },
        updateUrl ? createNavigationUrlCmd() : undefined
      ];
    }
    case "Energy": {
      const energyProduct = productData.products.find(
        obj => obj.id === system.file.systemTypeId + "OPE"
      );

      const [energyState, energyCmd] = Energy.init(
        system,
        sharedState,
        energyProduct
      );
      return [
        {
          type: initModal.type,
          state: energyState,
          title: sharedState.translate(LanguageTexts.energy()),
          wikiJsUrl: energyProduct?.wikiJsPath
            ? wikiJsUrl + "/" + energyProduct.wikiJsPath
            : undefined
        },
        Cmd.batch([
          Cmd.map(DispatchActions.dispatchEnergy, energyCmd),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }

    case "MCompare": {
      const [mCompare, mCompareCmd] = MCompare.init(
        sharedState,
        system,
        productData.products
      );
      return [
        {
          type: initModal.type,
          state: mCompare,
          title: sharedState.translate(LanguageTexts.mCompare())
        },
        Cmd.batch([
          Cmd.map(DispatchActions.dispatchMCompare, mCompareCmd),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }
    case "PlenumSize": {
      const [plenumState, plenumCmd] = PlenumSize.init(sharedState, system);
      return [
        {
          type: initModal.type,
          state: plenumState,
          title: sharedState.translate(LanguageTexts.systemSize())
        },
        Cmd.batch([
          Cmd.map(DispatchActions.dispatchPlenumSize, plenumCmd),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }
    case "MultipleSystemsEditor": {
      const sysComponent = system.components.find(component =>
        component.productId.endsWith("SYS")
      );
      const dhuComponent = system.components.find(component =>
        component.productId.endsWith("DHU")
      );
      const opcComponents = system.components.filter(component =>
        component.productId.endsWith("OPC")
      );

      const operatingCases = opcComponents.map(component => {
        return {
          id: component.id,
          settings: component.properties
            ? component.properties
            : PropertyValueSet.Empty
        };
      });

      const [multipleSystemsEditorState, multipleSystemsEditorCmd] =
        MultipleSystemsEditor.init(
          sharedState,
          system.file.systemTypeId,
          sysComponent,
          dhuComponent,
          operatingCases,
          system.climateSettings
        );
      return [
        {
          type: initModal.type,
          state: multipleSystemsEditorState,
          title: "multiple-systems-editor"
        },
        Cmd.batch([
          updateUrl ? createNavigationUrlCmd() : undefined,
          Cmd.map(
            DispatchActions.dispatchMultipleSystemsEditor,
            multipleSystemsEditorCmd
          )
        ])
      ];
    }
    case "DataCenterEditor": {
      const sysProduct = productData.products.find(p => p.id.endsWith("SYS"))!;
      const sysComponent = system.components.find(component =>
        component.productId.endsWith("SYS")
      )!;
      const opcComponents = system.components.filter(component =>
        component.productId.endsWith("OPC")
      )!;

      const operatingCases = opcComponents.map(component => {
        return {
          id: component.id,
          settings: component.properties
            ? component.properties
            : PropertyValueSet.Empty
        };
      });

      const [dataCenterEditorState, dataCenterEditorCmd] =
        DataCenterEditor.init(
          sharedState,
          system.file.systemTypeId,
          sysProduct,
          sysComponent,
          operatingCases,
          system.climateSettings
        );
      return [
        {
          type: initModal.type,
          state: dataCenterEditorState,
          title: "data-center-editor"
        },
        Cmd.batch([
          Cmd.map(
            DispatchActions.dispatchDataCenterEditor,
            dataCenterEditorCmd
          ),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }

    case "CondensationAnalysis": {
      const [condensationState, condensationCmd] = CondensationAnalysis.init(
        sharedState,
        system
      );

      return [
        {
          type: initModal.type,
          state: condensationState,
          title: "condensation-analysis"
        },
        Cmd.batch([
          Cmd.map(
            DispatchActions.dispatchCondensationAnalysis,
            condensationCmd
          ),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }
    case "LockedSystemCalculation": {
      const operatingCaseComponents = system.components.filter(component =>
        component.productId.endsWith("OPC")
      );

      const operatingCases = operatingCaseComponents.map(component => {
        return {
          id: component.id,
          settings: component.properties
            ? component.properties
            : PropertyValueSet.Empty
        };
      });

      const product = productData.products.find(product =>
        product.id.endsWith("OPC")
      )!;

      const fluidCoilsComponents = system.components.filter(component =>
        LockedSystemCalculation.fluidCoilCodes.includes(
          component.productId.slice(-3)
        )
      )!;

      const [lockedSystemCalculationState, lockedSystemCalculationCmd] =
        LockedSystemCalculation.init(
          sharedState,
          product,
          system.climateSettings,
          fluidCoilsComponents,
          operatingCases
        );

      return [
        {
          type: initModal.type,
          state: lockedSystemCalculationState,
          title: sharedState.translate(LanguageTexts.serviceCalculation())
        },
        Cmd.batch([
          Cmd.map(
            DispatchActions.dispatchLockedSystemCalculation,
            lockedSystemCalculationCmd
          ),
          updateUrl ? createNavigationUrlCmd() : undefined
        ])
      ];
    }

    case "WiringDiagram": {
      const [wiringDiagramState, wiringDiagramCmd] = WiringDiagram.init(
        system.id,
        sharedState
      );
      return [
        {
          type: initModal.type,
          state: wiringDiagramState,
          title: sharedState.translate(LanguageTexts.wiringDiagramBuilder())
        },
        Cmd.map(DispatchActions.dispatchWiringDiagram, wiringDiagramCmd)
      ];
    }

    case "NoModal": {
      return [
        { type: "NoModal" },
        Navigation.pushUrlWithoutReload(createSystemBaseUrl())
      ];
    }
    default: {
      return exhaustiveCheck(initModal, true);
    }
  }
}

// tslint:disable-next-line
