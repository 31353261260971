import * as Types from "./types";
import { PropertyValueSet } from "@genesys/property";
import * as SystemStatus from "@genesys/shared/lib/enums/system-status";

export function steps(
  systemTypeId: string,
  newProperties: PropertyValueSet.PropertyValueSet
): Types.SystemTypeRegistryEntry {
  switch (systemTypeId) {
    case "DSP":
      return {
        steps: ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "MCD":
      return {
        steps: ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "MXO":
    case "MXN":
      return {
        steps:
          PropertyValueSet.getInteger("systemarrangement", newProperties) === 0
            ? ["initial-configuration", "operating-cases", "dhu-size-selector"]
            : ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "ICE":
      return {
        steps: ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "MLP":
      return {
        steps:
          PropertyValueSet.getInteger("systemarrangement", newProperties) === 0
            ? ["initial-configuration", "operating-cases", "dhu-size-selector"]
            : ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "FAA":
    case "FAU":
    case "HUM":
      return {
        steps: [
          "initial-configuration",
          "operating-cases",
          "hum-size-selector"
        ],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "DCX":
    case "DEB":
    case "DCA":
      return {
        steps: [
          "initial-configuration",
          "operating-cases",
          "data-center-size-selector"
        ],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "ECC":
      return {
        steps: [
          "initial-configuration",
          "operating-cases",
          "ecc-size-selector"
        ],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "ISA":
    case "ICA":
    case "HCD":
    case "LDP":
      return {
        steps: ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "SPA":
    case "PST":
    case "HCU":
    case "GES":
    case "MCA":
    case "MCX":
    case "MAA":
    case "MAB":
    case "MAC":
    case "HCX":
    case "DDS":
    case "DSS":
    case "DES":
    case "HYG":
    case "EPC":
      return {
        steps: ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "EPX":
      return {
        steps: [],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "SB1":
      return {
        steps: ["initial-configuration"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    default:
      throw new Error("System type '" + systemTypeId + "' is not registred");
  }
}

export const isRegisteredSystemType = (systemType: string) => {
  try {
    steps(systemType, PropertyValueSet.Empty);
    return true;
  } catch (err) {
    return false;
  }
};
