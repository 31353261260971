import { exhaustiveCheck } from "ts-exhaustive-check";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as OperationTime from "@genesys/client-core/lib/operation-time-dialog";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Quantity, Unit } from "@genesys/uom";
import { Cmd } from "@typescript-tea/core";
import * as OperationTimeGen2 from "../../../../operation-time-manager";
import * as PsychrometricChart from "../../../../psychrometric-chart";
import * as PropertiesSelector from "../../../../properties-selector";
import * as GraphQlTypes from "../../../../graphql-types";
import * as SharedState from "../../../../shared-state";
import * as Product from "../../../product";
import * as System from "../../../system";
import { BinListViewType, BinType, BinVisualizer, ChartType } from "../types";
import { generateBinCasesQuery, moistureLoadBinsQuery } from "../queries";
import { UserEnergySettings } from "../types";
import {
  createMoistureLoadRows,
  staticBinNames
} from "../../../../moisture-load-calculation";
import {
  getBinFields,
  getOutdoorAirHumidityPropertyName,
  getOutdoorAirTemperaturePropertyName,
  buildSettingsDictionary,
  getMoistureLoadHeaders
} from "./functions/functions";
import { parsetMoistureLoadBinCases } from "./functions/moisture-load-bin-import";
import {
  getInitialPsychrometricChartSettings,
  getOperationTime,
  getBinType,
  getClimateCoolingDataType,
  getRoofTemperatureCompensationForEPC,
  getBinSize,
  getInitialBinsCasesFromSystem,
  createBinSelections,
  getInitialOperatingCaseSelectors,
  initializeOperatingCasePropertySelectors,
  initializeDefaultValuesSelectorState,
  getUseEnglishUnits
} from "./functions/initialize-functions";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";

export type State = {
  readonly energyProduct: Product.Product | undefined;
  readonly binCases: ReadonlyArray<SharedEnergyTools.BinCase> | undefined;
  readonly BinCasesData: GraphQlTypes.GenerateBinCases | undefined;
  readonly isBinCasesDataLoading: boolean;
  readonly binSelections: PropertyValueSet.PropertyValueSet | undefined;
  readonly initialBinSelections: PropertyValueSet.PropertyValueSet | undefined;
  readonly operatingCaseSelectorStates:
    | ReadonlyArray<PropertiesSelector.State>
    | undefined;
  readonly resetOperatingCaseSelectorStates:
    | ReadonlyArray<PropertiesSelector.State>
    | undefined;
  readonly initialOperatingCaseSelectorStates:
    | ReadonlyArray<PropertiesSelector.State>
    | undefined;
  readonly defaultValuesSelectorState: PropertiesSelector.State | undefined;
  readonly psychrometricChartState: PsychrometricChart.State | undefined;
  readonly operationTimeState: OperationTimeGen2.State;
  readonly userEnergySettingsBin: UserEnergySettings | undefined;
  readonly selectedBinVisualizer: BinVisualizer;
  readonly selectedBinListView: BinListViewType;
  readonly selectedChartType: ChartType;
  readonly moistureLoadBinQuery: GraphQlTypes.MoistureLoadBinCases | undefined;
};

export const init = (
  sharedState: SharedState.State,
  system: System.System,
  binLocation:
    | GraphQlTypes.EnergyInputQueryProduct["product"]["binDataLocations"][0]
    | undefined,
  energyProduct: Product.Product | undefined,
  initialEnergyEmissionsSettings: PropertyValueSet.PropertyValueSet,
  initalCostSettings: PropertyValueSet.PropertyValueSet,
  currentHourlyDataVersion: string
): [State, Cmd<Action>?] => {
  const systemBinSelections = PropertyValueSet.fromString(
    system.binSelections || ""
  );

  const userEnergySettings = buildSettingsDictionary(
    sharedState.user.settings.energySettings
  );

  const useEnglishUnits = getUseEnglishUnits(
    systemBinSelections,
    userEnergySettings,
    sharedState.user.settings.selectedAmountProfile.name !== "SI"
  );

  const initalOperationTime = getOperationTime(systemBinSelections);
  const [operationTimeState] = OperationTimeGen2.init(initalOperationTime);

  const initialBinSize = getBinSize(systemBinSelections, userEnergySettings);

  const initialBinType = getBinType(systemBinSelections, system);

  const initailCimateCoolingDataType = getClimateCoolingDataType(
    systemBinSelections,
    system.climateSettings
  );

  const initialRoofTemperatureCompensation =
    system.file.systemTypeId === "EPC"
      ? getRoofTemperatureCompensationForEPC(systemBinSelections)
      : undefined;

  const binSelections = createBinSelections(
    initialBinSize,
    operationTimeState!,
    initialBinType,
    initailCimateCoolingDataType,
    useEnglishUnits !== 1,
    initialRoofTemperatureCompensation,
    currentHourlyDataVersion,
    PropertyValueSet.get(
      KnownProperties.atmosphericPressure,
      system.climateSettings
    )!,
    binLocation
  );

  const completeBinSelections = PropertyValueSet.merge(
    binSelections,
    PropertyValueSet.merge(initialEnergyEmissionsSettings, initalCostSettings)
  );

  const hasBinSelectionsChanged = !PropertyValueSet.equals(
    systemBinSelections,
    completeBinSelections
  );

  const binCases =
    system.status === SystemStatus.LockSuccess || !hasBinSelectionsChanged
      ? getInitialBinsCasesFromSystem(system)
      : undefined;

  const operatingCaseSelectorStates = getInitialOperatingCaseSelectors(
    binCases,
    system,
    energyProduct
  );

  const defaultValuesSelectorState = initializeDefaultValuesSelectorState(
    operatingCaseSelectorStates
  );

  const selectedBinType = PropertyValueSet.getValue(
    KnownProperties.binType,
    binSelections
  ).value as BinType;

  const selectedChartType = getChartType(
    sharedState.user.settings.selectedAmountProfile.name === "SI" ? "SI" : "IP",
    sharedState.user.settings.psychrometricChartSettings.chartType === "mollier"
      ? "Mollier"
      : "Psychrometric"
  );

  const userEnergySettingsBin = {
    [KnownProperties.energyBinSize]: initialBinSize.toString(),
    [KnownProperties.energyOperationTime]:
      OperationTime.presetName(initalOperationTime),
    [KnownProperties.binUseEnglishUnits]: useEnglishUnits.toString()
  };

  const generateBinCasesAction =
    selectedBinType === "MoistureLoad" && system.moistureLoadInfo
      ? getMoistureLoadBinCases(
          binSelections,
          sharedState,
          system.moistureLoadInfo.id
        )
      : !binCases || !binCases.length
      ? generateBinCases(binSelections, sharedState, selectedChartType)
      : undefined;

  return [
    {
      initialBinSelections: systemBinSelections,
      binCases: binCases,
      operationTimeState: operationTimeState,
      isBinCasesDataLoading: false,
      binSelections: binSelections,
      selectedBinListView: "standard",
      selectedChartType: selectedChartType,
      selectedBinVisualizer: "List",
      userEnergySettingsBin: userEnergySettingsBin,
      initialOperatingCaseSelectorStates: operatingCaseSelectorStates,
      operatingCaseSelectorStates: operatingCaseSelectorStates,
      resetOperatingCaseSelectorStates: operatingCaseSelectorStates,
      defaultValuesSelectorState: defaultValuesSelectorState,
      energyProduct: energyProduct,
      BinCasesData: undefined,
      psychrometricChartState: undefined,
      moistureLoadBinQuery: undefined
    },
    generateBinCasesAction
  ];
};

function getChartType(
  measureSystem: "SI" | "IP",
  chart: "Mollier" | "Psychrometric"
): ChartType {
  if (measureSystem === "IP") {
    return chart === "Mollier" ? "mollier_IP" : "psycrom_IP";
  }
  return chart === "Mollier" ? "mollier_SI" : "psycrom_IP";
}

export const Action = ctorsUnion({
  dispatchPsychrometricChart: (action: PsychrometricChart.Action) => ({
    action
  }),
  dispatchOperationTime: (action: OperationTimeGen2.Action) => ({
    action
  }),
  dispatchOperatingCaseSelector: (
    index: number,
    action: PropertiesSelector.Action
  ) => ({
    index,
    action
  }),
  dispatchDefaultValuesSelector: (action: PropertiesSelector.Action) => ({
    action
  }),
  resetOperatingCaseSelector: (index: number) => ({ index }),
  resetAllOperatingCaseSelectors: () => ({}),
  binCasesQueryReceived: (
    data: GraphQlTypes.GenerateBinCases,
    binSelections: PropertyValueSet.PropertyValueSet,
    chartType?: ChartType
  ) => ({
    data,
    binSelections,
    chartType
  }),
  binToolsInputOnSelectionChange: (
    energySettings: {
      readonly [key: string]: string;
    },
    binSelections: PropertyValueSet.PropertyValueSet,
    binCases: ReadonlyArray<SharedEnergyTools.BinCase>
  ) => ({ binSelections, binCases, energySettings }),

  generateBinCases: (binSelections: PropertyValueSet.PropertyValueSet) => ({
    binSelections
  }),
  getMoistureLoadBinCases: (
    binSelections: PropertyValueSet.PropertyValueSet
  ) => ({ binSelections }),
  moistureLoadBinCasesQueryRecieved: (
    data: GraphQlTypes.MoistureLoadBinCases,
    binSelections: PropertyValueSet.PropertyValueSet
  ) => ({ data, binSelections }),
  setBinCases: (binCases: ReadonlyArray<SharedEnergyTools.BinCase>) => ({
    binCases
  }),
  setBinVisualizer: (binVisualizer: BinVisualizer) => ({ binVisualizer }),
  setBinListView: (binListView: BinListViewType) => ({ binListView }),
  setChartType: (chartType: ChartType) => ({ chartType }),
  setEnergySettingsAndBinSelections: (
    energySettings: {
      readonly [key: string]: string;
    },
    binSelections: PropertyValueSet.PropertyValueSet
  ) => ({
    energySettings,
    binSelections
  }),
  toggleOperationTime: () => ({}),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  system: System.System
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "dispatchPsychrometricChart": {
      if (state.psychrometricChartState === undefined) {
        return [state];
      }
      const [psychrometricChartState, psychrometricChartCmd, sharedStateCmd] =
        PsychrometricChart.update(
          action.action,
          state.psychrometricChartState,
          sharedState
        );
      return [
        {
          ...state,
          psychrometricChartState
        },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd),
        sharedStateCmd
      ];
    }
    case "dispatchOperationTime": {
      if (state.operationTimeState === undefined) {
        return [state];
      }
      const [newOperationTimeState] = OperationTimeGen2.update(
        action.action,
        state.operationTimeState
      );

      const binSelections = newOperationTimeState
        ? PropertyValueSet.merge(
            PropertyValueSet.setText(
              KnownProperties.binOperationTime,
              OperationTime.presetName(newOperationTimeState),
              OperationTime.toPropertyValueSet(newOperationTimeState)
            ),
            state.binSelections ?? PropertyValueSet.Empty
          )
        : state.binSelections;

      return [
        {
          ...state,
          binCases: [],
          operatingCaseSelectorStates: [],
          defaultValuesSelectorState: undefined,
          operationTimeState: newOperationTimeState,
          binSelections: binSelections
        }
      ];
    }
    case "dispatchOperatingCaseSelector": {
      if (state.operatingCaseSelectorStates === undefined) {
        return [state];
      }

      let newBinSelections = state.binSelections;

      if (
        // Change to custom if outdoor data has been changed (not really necessary anymore since we have set their value sources to locked)
        action.action.type === "updateProperties" &&
        action.action.changedPropertyNames.length &&
        (action.action.changedPropertyNames[0] ===
          getOutdoorAirTemperaturePropertyName(action.action.properties) ||
          action.action.changedPropertyNames[0] ===
            getOutdoorAirHumidityPropertyName(action.action.properties))
      ) {
        const isCustomBinType =
          state.binSelections &&
          PropertyValueSet.getText(KnownProperties.binType, state.binSelections)
            ? (PropertyValueSet.getText(
                KnownProperties.binType,
                state.binSelections
              )! as BinType) === "Custom"
            : false;

        newBinSelections = !isCustomBinType
          ? PropertyValueSet.setText(
              KnownProperties.binType,
              "Custom",
              state.binSelections ?? PropertyValueSet.Empty
            )
          : state.binSelections;
      }

      const [
        newPropertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.operatingCaseSelectorStates[action.index],
        sharedState,
        "SkipCalculateProperties"
      );

      const newPropertiesSelectorStates = state.operatingCaseSelectorStates.map(
        (p, ix) => {
          return ix === action.index ? newPropertiesSelectorState : p;
        }
      );

      return [
        {
          ...state,
          operatingCaseSelectorStates: newPropertiesSelectorStates,
          binSelections: newBinSelections
        },
        Cmd.map(
          cmdAction =>
            Action.dispatchOperatingCaseSelector(action.index, cmdAction),
          propertiesSelectorCmd
        ),
        propertiesSelectorSharedAction
      ];
    }
    case "dispatchDefaultValuesSelector": {
      if (state.defaultValuesSelectorState === undefined) {
        return [state];
      }

      let newOperatingCaseSelectorStates = state.operatingCaseSelectorStates;
      let newBinSelections = state.binSelections;

      if (
        action.action.type === "updateProperties" &&
        action.action.changedPropertyNames.length &&
        state.operatingCaseSelectorStates
      ) {
        const changedPropertyName = action.action.changedPropertyNames[0];
        const properties = action.action.properties;
        const newDefaultAmount = PropertyValueSet.getAmount(
          changedPropertyName,
          properties
        );

        newOperatingCaseSelectorStates = newDefaultAmount
          ? state.operatingCaseSelectorStates.map(o => ({
              properties: PropertyValueSet.setAmount(
                changedPropertyName,
                newDefaultAmount,
                o.properties
              )
            }))
          : state.operatingCaseSelectorStates;

        const isCustomBinType =
          state.binSelections &&
          PropertyValueSet.getText(KnownProperties.binType, state.binSelections)
            ? (PropertyValueSet.getText(
                KnownProperties.binType,
                state.binSelections
              )! as BinType) === "Custom"
            : false;

        newBinSelections = // Change to custom if outdoor data has been changed (not really necessary anymore since we have set their value sources to locked)
          !isCustomBinType &&
          (changedPropertyName ===
            getOutdoorAirTemperaturePropertyName(properties) ||
            changedPropertyName ===
              getOutdoorAirHumidityPropertyName(properties))
            ? PropertyValueSet.setText(
                KnownProperties.binType,
                "Custom",
                state.binSelections ?? PropertyValueSet.Empty
              )
            : state.binSelections;
      }

      const [
        newPropertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        state.defaultValuesSelectorState,
        sharedState,
        "SkipCalculateProperties"
      );

      return [
        {
          ...state,
          defaultValuesSelectorState: newPropertiesSelectorState,
          operatingCaseSelectorStates: newOperatingCaseSelectorStates,
          binSelections: newBinSelections
        },
        Cmd.map(
          cmdAction => Action.dispatchDefaultValuesSelector(cmdAction),
          propertiesSelectorCmd
        ),
        propertiesSelectorSharedAction
      ];
    }
    case "resetOperatingCaseSelector": {
      if (
        !state.operatingCaseSelectorStates ||
        !state.resetOperatingCaseSelectorStates
      ) {
        return [state];
      }

      const newOperatingCaseSelectorStates =
        state.operatingCaseSelectorStates.map((c, ix) => {
          if (ix === action.index) {
            return {
              ...c,
              properties: state.resetOperatingCaseSelectorStates![ix].properties
            };
          } else {
            return c;
          }
        });

      return [
        {
          ...state,
          operatingCaseSelectorStates: newOperatingCaseSelectorStates
        }
      ];
    }
    case "resetAllOperatingCaseSelectors": {
      return [
        {
          ...state,
          operatingCaseSelectorStates: state.resetOperatingCaseSelectorStates,
          defaultValuesSelectorState: initializeDefaultValuesSelectorState(
            state.resetOperatingCaseSelectorStates
          )
        }
      ];
    }
    case "binCasesQueryReceived": {
      const binCases = action.data.product.climateDataBins.map(bin => {
        const midPointTemp = bin.midPointTemp
          ? PropertyValue.getAmount<Quantity.Temperature>(
              PropertyValue.fromString(bin.midPointTemp)!
            )!
          : undefined;

        const midPointWetTemp = bin.midPointWetTemp
          ? PropertyValue.getAmount<Quantity.WetTemperature>(
              PropertyValue.fromString(bin.midPointWetTemp)!
            )!
          : undefined;

        const midPointDewPointTemp = bin.midPointDewPointTemp
          ? PropertyValue.getAmount<Quantity.DewPointTemperature>(
              PropertyValue.fromString(bin.midPointDewPointTemp)!
            )!
          : undefined;
        const midPointSpecificEnthalpy = bin.midPointSpecificEnthalpy
          ? PropertyValue.getAmount<Quantity.SpecificEnthalpy>(
              PropertyValue.fromString(bin.midPointSpecificEnthalpy)!
            )!
          : undefined;

        const midPointHumidityRatio = bin.midPointHumidityRatio
          ? PropertyValue.getAmount<Quantity.HumidityRatio>(
              PropertyValue.fromString(bin.midPointHumidityRatio)!
            )!
          : undefined;

        const midPointHourly = bin.midPointHourly
          ? PropertyValue.getAmount<Quantity.HumidityRatio>(
              PropertyValue.fromString(bin.midPointHourly)!
            )!
          : undefined;

        return {
          binId: bin.binId,
          midPointTemp: midPointTemp,
          midPointWetTemp: midPointWetTemp,
          midPointDewPointTemp: midPointDewPointTemp,
          midPointSpecificEnthalpy: midPointSpecificEnthalpy,
          midPointHumidityRatio,
          midPointHourly,

          averageTemperature: PropertyValue.getAmount<Quantity.Temperature>(
            PropertyValue.fromString(bin.averageTemp)!
          )!,
          averageHumidity: PropertyValue.getAmount<Quantity.HumidityRatio>(
            PropertyValue.fromString(bin.humidity)!
          )!,
          windSpeed: PropertyValue.getAmount<Quantity.Velocity>(
            PropertyValue.fromString(bin.wind)!
          )!,
          binPressure: PropertyValue.getAmount<Quantity.Pressure>(
            PropertyValue.fromString(bin.binPressure)!
          )!,
          binTime: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTime)!
          )!,
          binTimeJanuary: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeJanuary)!
          )!,
          binTimeFebruary: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeFebruary)!
          )!,
          binTimeMarch: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeMarch)!
          )!,
          binTimeApril: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeApril)!
          )!,
          binTimeMay: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeMay)!
          )!,
          binTimeJune: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeJune)!
          )!,
          binTimeJuly: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeJuly)!
          )!,
          binTimeAugust: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeAugust)!
          )!,
          binTimeSeptember: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeSeptember)!
          )!,
          binTimeOctober: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeOctober)!
          )!,
          binTimeNovember: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeNovember)!
          )!,
          binTimeDecember: PropertyValue.getAmount<Quantity.Duration>(
            PropertyValue.fromString(bin.binTimeDecember)!
          )!
        } as SharedEnergyTools.BinCase;
      });

      const binFields = getBinFields(action.binSelections, action.data);

      const [psychrometricChartState, psychrometricChartCmd] =
        PsychrometricChart.init(
          getInitialPsychrometricChartSettings(
            action.binSelections,
            action.chartType || "mollier_SI",
            binFields,
            sharedState,
            binCases
          )
        );

      const operatingCaseSelectorStates = binCases.length
        ? initializeOperatingCasePropertySelectors(
            binCases,
            system,
            state.energyProduct
          )
        : undefined;

      const defaultValuesSelectorState = initializeDefaultValuesSelectorState(
        operatingCaseSelectorStates
      );

      return [
        {
          ...state,
          binCases: binCases,
          binSelections: action.binSelections,
          resetOperatingCaseSelectorStates: operatingCaseSelectorStates,
          operatingCaseSelectorStates: operatingCaseSelectorStates,
          defaultValuesSelectorState: defaultValuesSelectorState,
          isBinCasesDataLoading: false,
          BinCasesData: action.data,
          psychrometricChartState
        },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd)
      ];
    }

    case "binToolsInputOnSelectionChange": {
      const operatingCaseSelectorStates = action.binCases
        ? initializeOperatingCasePropertySelectors(
            action.binCases,
            system,
            state.energyProduct
          )
        : undefined;

      const defaultValuesSelectorState = initializeDefaultValuesSelectorState(
        operatingCaseSelectorStates
      );

      const userEnergySettingsBin = {
        [KnownProperties.energyBinSize]:
          action.energySettings[KnownProperties.energyBinSize],
        [KnownProperties.binUseEnglishUnits]:
          action.energySettings[KnownProperties.binUseEnglishUnits],
        [KnownProperties.energyOperationTime]:
          action.energySettings[KnownProperties.energyOperationTime]
      };

      return [
        {
          ...state,
          binCases: action.binCases,
          binSelections: action.binSelections,
          userEnergySettingsBin: userEnergySettingsBin,
          resetOperatingCaseSelectorStates: operatingCaseSelectorStates,
          operatingCaseSelectorStates: operatingCaseSelectorStates,
          defaultValuesSelectorState: defaultValuesSelectorState
        }
      ];
    }

    case "generateBinCases": {
      return [
        {
          ...state,
          isBinCasesDataLoading: true,
          binCases: [],
          operatingCaseSelectorStates: undefined,
          defaultValuesSelectorState: undefined,
          psychrometricChartState: undefined,
          binSelections: action.binSelections
        },
        generateBinCases(
          action.binSelections,
          sharedState,
          state.selectedChartType
        )
      ];
    }

    case "getMoistureLoadBinCases": {
      return [
        { ...state, binCases: [], isBinCasesDataLoading: true },
        getMoistureLoadBinCases(
          action.binSelections,
          sharedState,
          system.moistureLoadInfo!.id!
        )
      ];
    }

    case "moistureLoadBinCasesQueryRecieved": {
      const results =
        action.data.user.moistureLoad!.moistureLoadResult!.diagramResults!.map(
          PropertyValueSet.fromString
        );

      const rows = createMoistureLoadRows(
        results,
        staticBinNames,
        sharedState,
        true
      );
      const headers = getMoistureLoadHeaders(
        results,
        staticBinNames,
        sharedState,
        "MoistureLoadResult.ListVisualizer"
      );

      const resultRows = [headers, ...rows.map(x => x.results)];
      const binCases = parsetMoistureLoadBinCases(system, resultRows);

      const operatingCaseSelectorStates = binCases.length
        ? initializeOperatingCasePropertySelectors(
            binCases,
            system,
            state.energyProduct
          )
        : undefined;

      const binSize = parseInt(
        action.data.user.moistureLoad!.moistureloadInput!.binSize
      );

      const binSelections = PropertyValueSet.setInteger(
        "binsize",
        binSize,
        action.binSelections
      );

      return [
        {
          ...state,
          binSelections: binSelections,
          operatingCaseSelectorStates: operatingCaseSelectorStates,
          initialOperatingCaseSelectorStates: operatingCaseSelectorStates,
          resetOperatingCaseSelectorStates: operatingCaseSelectorStates,
          moistureLoadBinQuery: action.data,
          binCases: binCases,
          isBinCasesDataLoading: false
        }
      ];
    }

    case "setChartType": {
      const binFields = getBinFields(state.binSelections, state.BinCasesData);
      const [psychrometricChartState, psychrometricChartCmd] =
        PsychrometricChart.init(
          getInitialPsychrometricChartSettings(
            state.binSelections,
            action.chartType,
            binFields,
            sharedState,
            state.binCases!
          )
        );
      return [
        {
          ...state,
          psychrometricChartState,
          selectedChartType: action.chartType
        },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd)
      ];
    }
    case "setBinVisualizer": {
      const shouldFetchPsychpsychrometricChart =
        action.binVisualizer === "Chart" &&
        state.psychrometricChartState === undefined;
      return [
        {
          ...state,
          isBinCasesDataLoading: shouldFetchPsychpsychrometricChart,
          binCases: shouldFetchPsychpsychrometricChart ? [] : state.binCases,
          selectedBinVisualizer: action.binVisualizer
        },
        shouldFetchPsychpsychrometricChart && state.binSelections
          ? generateBinCases(
              state.binSelections,
              sharedState,
              state.selectedChartType
            )
          : undefined
      ];
    }
    case "setBinListView": {
      return [
        {
          ...state,
          selectedBinListView: action.binListView
        }
      ];
    }
    case "setBinCases": {
      const operatingCaseSelectorStates = action.binCases
        ? initializeOperatingCasePropertySelectors(
            action.binCases,
            system,
            state.energyProduct
          )
        : undefined;

      const defaultValuesSelectorState = initializeDefaultValuesSelectorState(
        operatingCaseSelectorStates
      );

      return [
        {
          ...state,
          binCases: action.binCases,
          resetOperatingCaseSelectorStates: operatingCaseSelectorStates,
          operatingCaseSelectorStates: operatingCaseSelectorStates,
          defaultValuesSelectorState: defaultValuesSelectorState
        }
      ];
    }
    case "setEnergySettingsAndBinSelections": {
      const userEnergySettingsBin = {
        [KnownProperties.energyBinSize]:
          action.energySettings[KnownProperties.energyBinSize],
        [KnownProperties.energyOperationTime]:
          action.energySettings[KnownProperties.energyOperationTime],
        [KnownProperties.binUseEnglishUnits]:
          action.energySettings[KnownProperties.binUseEnglishUnits]
      };

      return [
        {
          ...state,
          binSelections: action.binSelections,
          userEnergySettingsBin: userEnergySettingsBin
        }
      ];
    }
    case "toggleOperationTime": {
      const [operationTimeState] = OperationTimeGen2.init();
      return [
        {
          ...state,
          operationTimeState:
            state.operationTimeState === undefined
              ? operationTimeState
              : state.operationTimeState
        }
      ];
    }
    case "onFormatChanged": {
      return [
        state,
        undefined,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

export function getBinSelections(state: State) {
  return state.binSelections;
}

export function getBinCases(state: State) {
  return state.binCases;
}

export function getOperatingCases(
  state: State
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  if (!state.operatingCaseSelectorStates) {
    return [];
  }

  return state.operatingCaseSelectorStates.map(pss =>
    PropertiesSelector.getSelectedProperties(pss)
  );
}

export function getInitialBinSelectionAndOperatingCases(state: State) {
  if (
    !state.initialOperatingCaseSelectorStates ||
    !state.initialBinSelections
  ) {
    return undefined;
  }

  return {
    initialBinSelections: state.initialBinSelections,
    initialOperatingCases: state.initialOperatingCaseSelectorStates.map(o =>
      PropertiesSelector.getSelectedProperties(o)
    )
  };
}

function generateBinCases(
  binSelections: PropertyValueSet.PropertyValueSet,
  sharedState: SharedState.State,
  chartType?: ChartType
): Cmd<Action> | undefined {
  return sharedState.graphQL.queryProductCmd<
    GraphQlTypes.GenerateBinCases,
    GraphQlTypes.GenerateBinCasesVariables,
    Action
  >(
    generateBinCasesQuery,
    {
      input: PropertyValueSet.toString(binSelections)
    },
    data => Action.binCasesQueryReceived(data, binSelections, chartType)
  );
}

function getMoistureLoadBinCases(
  binSelections: PropertyValueSet.PropertyValueSet,
  sharedState: SharedState.State,
  moistureLoadId: string
): Cmd<Action> | undefined {
  return sharedState.graphQL.queryUserCmd<
    GraphQlTypes.MoistureLoadBinCases,
    GraphQlTypes.MoistureLoadBinCasesVariables,
    Action
  >(
    moistureLoadBinsQuery,
    {
      id: moistureLoadId
    },
    data => Action.moistureLoadBinCasesQueryRecieved(data, binSelections)
  );
}

// tslint:disable-next-line
