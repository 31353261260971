import * as React from "react";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import { Spinner } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { PropertyValueSet } from "@genesys/property";
import * as SharedState from "../../../../shared-state";
import * as LocationSelectorGen2 from "../../../../location-selector";
import * as BinTools from "../../../../binning-tools";
import * as System from "../../../system";
import { BinType } from "../types";
import { State, Action } from "./state";
import { Root } from "./elements";
import {
  BinGenerationComponent,
  BinVisualizerComponent,
  CustomBinsImportComponent,
  MoistureLoadBinsImportComponent,
  BinTypeSelectorComponent
} from "./components";

export function BinGenerationView({
  state,
  system,
  sharedState,
  locationSelectorState,
  dispatch,
  dispatchLocationSelector
}: {
  readonly state: State;
  readonly system: System.System;
  readonly sharedState: SharedState.State;
  readonly locationSelectorState: LocationSelectorGen2.State;
  readonly dispatch: Dispatch<Action>;
  readonly dispatchLocationSelector: Dispatch<LocationSelectorGen2.Action>;
}) {
  const isSystemLocked = system.status >= SystemStatus.LockSuccess;
  const binSelections = state.binSelections;

  if (binSelections === undefined) {
    return <Root>{state.isBinCasesDataLoading && <Spinner />}</Root>;
  }

  const selectedBinType = PropertyValueSet.getValue(
    KnownProperties.binType,
    binSelections
  ).value as BinType;

  const binInfo: BinTools.BinInfo = {
    binCases: state.binCases,
    binSelections: binSelections,
    isBinCasesDataLoading: state.isBinCasesDataLoading,
    selectedBinListView: state.selectedBinListView,
    selectedBinVisualizer: state.selectedBinVisualizer,
    selectedChartType: state.selectedChartType,
    selectedBinType: selectedBinType
  };

  const energySettings = state.userEnergySettingsBin ?? {};

  const atmosphericPressure = PropertyValueSet.get(
    "atmosphericpressure",
    system.climateSettings
  );

  const binDataLocation = LocationSelectorGen2.GetSelectedLocation(
    locationSelectorState
  );

  return (
    <Root>
      {state.isBinCasesDataLoading && <Spinner />}

      <div>
        <BinTypeSelectorComponent
          energySettings={energySettings}
          isSystemLocked={isSystemLocked}
          binSelections={binInfo.binSelections}
          selectedBinType={binInfo.selectedBinType}
          hasMoistureLoadInfo={!!system.moistureLoadInfo}
          dispatch={dispatch}
          translate={sharedState.translate}
        />
        <div>
          {selectedBinType === "Generated" && (
            <BinGenerationComponent
              binInfo={binInfo}
              sharedState={sharedState}
              energySettings={energySettings}
              isSystemLocked={isSystemLocked}
              climateSettings={system.climateSettings}
              atmosphericPressure={atmosphericPressure}
              locationSelectorState={locationSelectorState}
              operationTimeState={state.operationTimeState}
              dispatch={dispatch}
              dispatchLocationSelector={dispatchLocationSelector}
            />
          )}
          {selectedBinType === "Custom" && (
            <CustomBinsImportComponent
              isSystemLocked={isSystemLocked}
              dispatch={dispatch}
              translate={sharedState.translate}
            />
          )}
          {selectedBinType === "MoistureLoad" && (
            <MoistureLoadBinsImportComponent
              system={system}
              sharedState={sharedState}
              isSystemLocked={isSystemLocked}
              moistureLoadBinQuery={state.moistureLoadBinQuery}
              dispatch={dispatch}
            />
          )}
        </div>
      </div>

      <BinVisualizerComponent
        binDataLocation={binDataLocation}
        system={system}
        binInfo={binInfo}
        sharedState={sharedState}
        atmosphericPressure={atmosphericPressure}
        psychrometricChartState={state.psychrometricChartState}
        dispatch={dispatch}
      />
    </Root>
  );
}
